const env = process.env.NODE_ENV;

let envApiUrl = "";

/*
    Note about "Mixed content blocked" error:
    -----------------------------------------
    This error may happend even if you use https in get request since traefik may first redirect it to the url with trailing slash
    You can debug this issue by curl:
    curl -L https://ipss.oruflow.com/api -v 2>&1|grep request
        * Issue another request to this URL: 'http://ipss.oruflow.com/api/'
        * Issue another request to this URL: 'https://ipss.oruflow.com/api/'
    Adding this line could resolve this issue:
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
    
    BUT if you already use trailing slash with api endpoint, issue disappears.
*/

if (env === "production") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === "staging") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}

export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
