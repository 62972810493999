<template>
  <div class="form-group">
    <label v-for="(el, ix) in optionList" :key="ix">
      <input
        :name="name"
        :value="ix"
        type="radio"
        v-on:change="$emit('update:modelValue', ix)"
        class="input-radio"
      />{{ el }}</label
    >
  </div>
</template>

<script>
export default {
  name: "FormOptions",
  props: ["optionList", "name", "value"],
  emits: ["update:modelValue"],
};
</script>

<style scoped>
label {
  display: flex;
  padding: 5px;
}

.form-group {
  text-align: left;
  width: 100%;
  margin: 5px 30px;
}
</style>
