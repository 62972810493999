<template>
  <div class="home">
    <img alt="Vue logo" style="width: 25%" src="../assets/oruflow_logo.png" />
    <div v-if="instituteList[$route.params.instituteId]">
      <p>
        <span style="text-decoration: underline; font-weight: bold">{{
          instituteList[$route.params.instituteId]
        }}</span>
        konumunda gerçekleştirilen muayene kapsamında IPSS adı verilen ankete
        yönlendirileceksiniz.
      </p>
      <p>&nbsp;</p>
      <p>Lütfen barkod numaranızı giriniz:</p>
      <form>
        <input v-model="barcodeNumber" type="text" />
        <button @click.prevent="goSurvey()">Devam</button>
      </form>
    </div>
    <p v-else>
      Yanlış bir adres girdiniz, lütfen kontrol ederek tekrar deneyiniz.
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      instituteList: {
        23: "Development #1",
        120: "ORUFLOW_120"
      },
      barcodeNumber: "",
    };
  },
  methods: {
    goSurvey() {
      let instituteId = this.$route.params.instituteId;
      let barcodeNumber = this.barcodeNumber;
      this.$router.push({ path: `/${instituteId}/${barcodeNumber}` });
    },
  },
};
</script>

<style scoped>
button {
  margin-left: 5px;
}
input {
  width: 10em;
}
</style>
