<template>
  <div class="form">
    <h1>{{ $t("ipss_header") }}</h1>

    <h3>1. {{ $t("incomplete_emptying_question") }}</h3>
    <FormOptions
      v-model="answers[0]"
      name="incomplete_emptying"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>2. {{ $t("frequency_question") }}</h3>
    <FormOptions
      v-model="answers[1]"
      name="frequency"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>3. {{ $t("intermittency_question") }}</h3>
    <FormOptions
      v-model="answers[2]"
      name="intermittency"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>4. {{ $t("urgency_question") }}</h3>
    <FormOptions
      v-model="answers[3]"
      name="urgency"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>5. {{ $t("weak_stream_question") }}</h3>
    <FormOptions
      v-model="answers[4]"
      name="weak_stream"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>6. {{ $t("straining") }}</h3>
    <FormOptions
      v-model="answers[5]"
      name="straining"
      :option-list="frequencyOptions"
    ></FormOptions>
    <h3>7. {{ $t("nocturia_question") }}</h3>
    <FormOptions
      v-model="answers[6]"
      name="nocturia"
      :option-list="times_options"
    ></FormOptions>
    <h3>8. {{ $t("qol_question") }}</h3>
    <FormOptions
      v-model="answers[7]"
      name="qol"
      :option-list="feel_options"
    ></FormOptions>

    <div style="text-align: center; margin-bottom: 15px">
      <label for="agree">
        <input
          v-model="policy"
          class="cbox"
          type="checkbox"
          id="agree"
          required="true"
          name="agree"
        />
      </label>
      <i18n-t keypath="term" tag="label" for="tos">
        <a href="#">{{ $t("tos") }}</a>
      </i18n-t>
    </div>

    <div style="text-align: center">
      <button @click="submit" style="font-size: 0.9em; padding: 0.3em">
        {{ $t("submit") }}
      </button>
    </div>
  </div>
</template>
<script>
import { apiUrl } from "@/env";
import FormOptions from "./FormOptions.vue";

export default {
  name: "Survey",
  data() {
    return {
      policy: false,
      answers: [-1, -1, -1, -1, -1, -1, -1, -1],
      frequencyOptions: [
        this.$t("frequency_options.not_at_all"),
        this.$t("frequency_options.less_than_five"),
        this.$t("frequency_options.less_than_half"),
        this.$t("frequency_options.half_the_time"),
        this.$t("frequency_options.more_than_half"),
        this.$t("frequency_options.almost_always"),
      ],
      times_options: [
        this.$t("times_options.none"),
        this.$t("times_options.one_time"),
        this.$t("times_options.two_times"),
        this.$t("times_options.three_times"),
        this.$t("times_options.four_times"),
        this.$t("times_options.five_times"),
      ],
      feel_options: [
        this.$t("feel_options.delighted"),
        this.$t("feel_options.pleased"),
        this.$t("feel_options.mostly_satisfied"),
        this.$t("feel_options.mixed"),
        this.$t("feel_options.mostly_dissatisfied"),
        this.$t("feel_options.unhappy"),
        this.$t("feel_options.terrible"),
      ],
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    // this.axios
    //   .get(`${apiUrl}/api/`)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // .finally(() => (this.loading = false));
      console.log(`${apiUrl}/api/survey/`)
  },
  components: {
    FormOptions,
  },
  methods: {
    submit() {
      let vm = this
      let barcodeNumber = this.$route.params.barcodeNumber
      let instituteId = this.$route.params.instituteId

      if (this.answers.includes(-1)) {
        alert("Lütfen bütün alanları doldurun.");
      } else if (!this.policy) {
        alert("Devam etmek için sözleşmeyi kabul etmelisiniz.");
      } else {
        this.axios
        .post(`${apiUrl}/api/survey/`, {
          device_id: instituteId,
          barcode_number: barcodeNumber,
          ipss: vm.answers.join(""),
        })
        .then((response) => {
          console.log("SUCCESS");
          console.log(response.data);
          this.$router.push({ path: `/finish` });
        })
        .catch((error) => {
          console.log("FAIL");
          console.log(error);
          alert("Beklenmeyen hata. Lütfen daha sonra tekrar deneyiniz.");
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form {
  width: 100%;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 100px;
}

@media (min-width: 480px) {
  .form {
    width: 50%;
  }
}

.cbox {
  width: 18px;
  height: 18px;
}

h3 {
  margin: 40px 0 0;
  padding: 7px;
}
h1 {
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
