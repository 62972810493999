import { createRouter, createWebHistory } from "vue-router";
import Welcome from "../views/Welcome.vue";
import Barcode from "../views/Barcode.vue";
import Survey from "../components/Survey.vue";
import Finish from "../views/Finish.vue";

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/:instituteId",
    name: "Barcode",
    component: Barcode,
  },
  {
    path: "/:instituteId/:barcodeNumber",
    name: "Survey",
    component: Survey,
  },
  {
    path: "/finish",
    name: "Finish",
    component: Finish,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
