export default {
  "ipss_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oruba IPSS"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GİZLİLİK VE GÜVENLİK POLİÇESİ"])},
  "privacy_policy_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni oku ve kabul et"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
  "ipss_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oruba IPSS Anketi"])},
  "barcode_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barkod giriniz"])},
  "frequency_options": {
    "not_at_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç"])},
    "less_than_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 defada 1 (seyrek)"])},
    "less_than_half": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarıdan az"])},
    "half_the_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarı yarıya"])},
    "more_than_half": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarıdan çok (sık)"])},
    "almost_always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemen her zaman"])}
  },
  "incomplete_emptying_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrar yaptıktan sonra idrar torbanızın tam boşalmadığı hissine kapıldınız mı?"])},
  "frequency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrar yaptıktan sonraki iki saat içinde tekrar idrar hissi duydunuz mu?"])},
  "intermittency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrar yaparken kesilip tekrar başlama oldu mu?"])},
  "urgency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrarınızı tutmakta zorlandığınız oldu mu?"])},
  "weak_stream_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrar akımında zayıflama hissettiniz mi?"])},
  "straining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, idrara başlarken ıkınma ya da zorlanma oldu mu?"])},
  "times_options": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç"])},
    "one_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kez"])},
    "two_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 kez"])},
    "three_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 kez"])},
    "four_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 kez"])},
    "five_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 ya da fazla"])}
  },
  "nocturia_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 1 ay içinde, gece kaç defa idrar hissi ile uyanıp tuvalete gitmeniz gerekiyor?"])},
  "feel_options": {
    "delighted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok mutlu"])},
    "pleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutlu"])},
    "mostly_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İyi"])},
    "mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazen iyi bazen kötü"])},
    "mostly_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğunlukla kötü"])},
    "unhappy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutsuz"])},
    "terrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berbat"])}
  },
  "qol_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayatınızın bundan sonraki bölümünde şikayetleriniz aynen devam ederse kendinizi nasıl hissedersiniz?"])},
  "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet kullanım şartları"])},
  "term": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "nı okudum ve onaylıyorum"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
  "result_has_been_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç gönderildi"])},
  "device_id_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cihaz numarası: "])},
  "continue_to_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen testinize devam edin"])}
}