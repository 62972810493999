<template>
  <div class="home">
    <p style="font-size:1.3em">Teşekkürler</p>
    <p style="font-size:1.3em">Anket başarıyla gönderildi.</p>
    <a style="margin-top:5em; display:block" href="https://orubatechnology.com/">https://orubatechnology.com/</a>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      instituteList: {
        23: "Development #1",
        120: "ORUFLOW_120"
      },
      barcodeNumber: "",
    };
  },
  methods: {
    goSurvey() {
      let instituteId = this.$route.params.instituteId;
      let barcodeNumber = this.barcodeNumber;
      this.$router.push({ path: `/${instituteId}/${barcodeNumber}` });
    },
  },
};
</script>

<style scoped>

</style>
