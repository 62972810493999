export default {
  "ipss_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oruba IPSS"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "privacy_policy_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read me"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "ipss_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oruba International Prostate Symptom Score"])},
  "barcode_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your barcode here"])},
  "frequency_options": {
    "not_at_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not at All"])},
    "less_than_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 1 in 5 Times"])},
    "less_than_half": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than Half the Time"])},
    "half_the_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Half the Time"])},
    "more_than_half": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than Half the Time"])},
    "almost_always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost Always"])}
  },
  "incomplete_emptying_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how often have you had the sensation of not emptying your bladder?"])},
  "frequency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how often have you had to urinate less than every two hours?"])},
  "intermittency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how often have you found you stopped and started again several times when you urinated?"])},
  "urgency_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how often have you found it difficult to postpone urination?"])},
  "weak_stream_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how often have you had a weak urinary  stream?"])},
  "straining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, hw often have you had to strain to start urination?"])},
  "times_options": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "one_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Time"])},
    "two_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Times"])},
    "three_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Times"])},
    "four_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 TImes"])},
    "five_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Times"])}
  },
  "nocturia_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month, how many times did you typically get up at night to urinate?"])},
  "feel_options": {
    "delighted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delighted"])},
    "pleased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleased"])},
    "mostly_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostly Satisfied"])},
    "mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed"])},
    "mostly_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostly Dissatisfied"])},
    "unhappy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhappy"])},
    "terrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrible"])}
  },
  "qol_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you were to spend the rest of your life with your urinary condition just the way it is now, how would you feel about that?"])},
  "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of service"])},
  "term": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I agree to the ", _interpolate(_list(0))])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumbit"])},
  "result_has_been_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result has been sent"])},
  "device_id_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device id is: "])},
  "continue_to_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please continue to your test"])}
}